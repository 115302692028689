import EDDLHelper from './EDDL/EDDLHelper';
// eslint-disable-next-line import/no-relative-packages
import EBAConfig from './EBAConfig/EBAConfig';

// install and expose the helper
window.VRT = window.VRT || {};
window.VRT.EBA = window.VRT.EBA || {};
window.VRT.EBA.EDDLHelper = EDDLHelper;

window.VRT.EBA.track = window.VRT.EBA.EDDLHelper.track;
window.VRT.EBA.trackUsingTemplate = window.VRT.EBA.EDDLHelper.trackUsingTemplate;
window.VRT.EBA.EBAConfig = EBAConfig;
window.VRT.EBA.sanitize = window.VRT.EBA.EDDLHelper.sanitize;

if (EBAConfig.ceddl_enabled) window.VRT.EBA.CEDDLSubscriber = 'placeholder';
if (EBAConfig.snowplow_enabled) window.VRT.EBA.SnowplowSubscriber = 'placeholder';
if (EBAConfig.gemius_enabled) window.VRT.EBA.GemiusSubscriber = 'placeholder';

window.dispatchEvent(new CustomEvent('EBAReady'));
