import logger from 'loglevel';
// eslint-disable-next-line import/no-relative-packages
import EBAConfig from '../EBAConfig/EBAConfig';

function EDDLHelperFactory() {
  // hoisted public
  const sanitize = (s) => {
    if (s === '') return '';
    if (!s) return null;
    return s
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  // =========
  // privates
  // =========

  const evalFunction = (functionName) => {
    switch (functionName) {
      case '$domain()': {
        return window.location.hostname;
      }
      case '$fragment()': {
        return window.location.hash.substring(1);
      }
      case '$lang()': {
        return document.documentElement.lang;
      }
      case '$now()': {
        return Date.now();
      }
      case '$params()': {
        const params = window.location.search;
        return params ? params.substring(1) : '';
      }
      case '$path()': {
        return window.location.pathname;
      }
      case '$referrer()': {
        return document.referrer;
      }
      case '$title()': {
        return sanitize(document.title);
      }
      case '$url()': {
        return window.location.origin + window.location.pathname;
      }
      case '$viewport()': {
        return `${window.innerWidth}x${window.innerHeight}`;
      }
      case '$platform()': {
        return 'web';
      }
      case '$env()': {
        return EBAConfig.environment || 'unknown';
      }
      default: {
        return null;
      }
    }
  };

  const instantiate = (eventOrContextTemplate, variables) => {
    const instance = { schema: eventOrContextTemplate.schema, data: {} };
    Object.entries(eventOrContextTemplate.data).forEach(([key, val]) => {
      if (typeof val !== 'string') {
        // non-string => just copy over from template to actual event
        instance.data[key] = val;
      } else if (val.startsWith('$') && val.endsWith('()')) {
        // it's a function
        const newval = evalFunction(val);
        if (newval) instance.data[key] = newval;
      } else if (val.startsWith('$')) {
        // it's a variable
        const newval = variables[val];
        if (newval) instance.data[key] = newval;
      } else instance.data[key] = val; // it's a hardcoded string in the template => just copy over
    });
    return instance;
  };

  // =========
  // publics
  // =========

  // heads up : more performant to call with just 1 (pre-merged-if-needed) variables keyvalue map
  // If more than one variableMap: order from least specific to most specific (overrides previous values for same var)
  // Variables in the template and variableMap should start with $
  // Functions in the template should start with $ and end with (), and be known functions (cfr evalFunction())
  // Check templates subfolder for default templates corresponding to the schemas, or bring your own with tweaks.
  // The testpage uses these templating helpers
  const instantiateTemplate = (template, variableMap = {}, ...extraVariableMaps) => {
    const instance = { context: [] };
    let variables = variableMap;
    if (extraVariableMaps.length > 0) {
      variables = {};
      Object.assign.apply(this, [variables, variableMap, ...extraVariableMaps]);
    }
    try {
      if (template.event) instance.event = instantiate(template.event, variables);
      if (template.context) {
        template.context.forEach((context) => {
          const contextInstance = instantiate(context, variables);
          if (Object.keys(contextInstance.data).length > 0) instance.context.push(contextInstance); // only add the instantiated context if it is non-empty
        });
      }
    } catch (e) {
      logger.warn('template instantiate failed');
      logger.warn(e.error);
      logger.warn(e.message);
      logger.warn(e.stack);
      return null;
    }
    return instance;
  };

  // cfr instantiateTemplate for usage instructions
  const trackUsingTemplate = (template, variableMap = {}, ...extraVariableMaps) => {
    logger.info('EBA EDDLHelper: trackUsingTemplate');
    // logger.info(JSON.stringify(window.digitalData, null, 2));
    const instance = instantiateTemplate.apply(this, [template, variableMap, ...extraVariableMaps]);
    if (instance) window.digitalData.events.push(instance);
  };

  const track = (event) => {
    window.digitalData.events.push(event);
  };

  const ensureEDDL = () => {
    // make sure there is a minimal dataLayer, so that trackUsingTemplate does not fail in case the EDDL is loaded async
    logger.info('EBA EDDLHelper: ensureEDDL before:');
    logger.info(JSON.stringify(window.digitalData, null, 2));
    window.digitalData = window.digitalData || {};
    window.digitalData.events = window.digitalData.events || [];
    window.digitalData.page = window.digitalData.page || EBAConfig.ceddl_initialPage || {};
    window.digitalData.media = window.digitalData.media || {};
    window.digitalData.user = window.digitalData.user || {};
    logger.info('EBA EDDLHelper: ensureEDDL after:');
    logger.info(JSON.stringify(window.digitalData, null, 2));
  };

  ensureEDDL();

  return {
    ensureEDDL,
    instantiateTemplate,
    trackUsingTemplate,
    track,
    logger,
    sanitize,
  };
}

export default EDDLHelperFactory();
